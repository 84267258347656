html {
  font-size: $main-fs;
  line-height: 22px;
}
body {
  background: #bbc2ce;
  font-family: $main-ff;
  font-size: 1rem;
  font-weight: $main-fw;
  line-height: $main-lh;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $headers-ff;
  font-weight: $headers-fw;
  line-height: $module;
  letter-spacing: .01em;
  margin-top: $module;
  margin-bottom: $module;
}
h1, .h1 {
  font-size: 4.2142857rem;
  line-height: 1.13;
  margin-bottom: 3.1428572rem;
  @media (max-width: 767px)  {
    font-size: 3rem;
  }
}
h2, .h2 {
  font-size: 3.1428571rem;
  line-height: 1;
  @media (max-width: 767px) {
    font-size: 2.6rem;
  }
}
h3, .h3 {
  font-size: 2.3571429rem;
  line-height: 1.34;
}
h4, .h4 {
  font-size: 1.7857143rem;
  line-height: 1.32;
}
h5, .h5 {
  font-size: 1.3571429rem;
  line-height: 1.21;
}
h6, .h6 {
  font-size: 1rem;
  line-height: 1.6;
}
p,
ul,
ol,
pre,
table,
blockquote,
.blockquote {
  margin-top: 0;
  margin-bottom: $module;

  &:last-child {
    margin-bottom: 0;
  }
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
hr, .hr {
  border: 1px solid;
  margin: -1px 0;
}
sub, sup {
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
a {
  color: main-color(A100);
  text-decoration: none;
  transition: color .2s $animation;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}
img {
  height: auto;
  max-width: 100%;
}
button {
  &:focus {
    outline: none;
  }
}
.form-group {
  align-items: center;
}
.form-message {
  left: 0;
  margin-top: .1rem;
  position: absolute;
  top: 100%;

  ::deep .rtl & {
    left: auto;
    right: 0;
  }
}
hr {
  border: 0;
  border-bottom: 1px solid rgba(#000,.1);
  height: 0;
  margin: $module 0;
}
//Scroll
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 12px rgba(#000,.3);
}
::-webkit-scrollbar {
  background: rgba(#fff,.2);
  position: absolute;
  width: 12px;
}
::-webkit-scrollbar:hover {
  display: block;
}
::-webkit-scrollbar-thumb {
  background-color: main-color(700);
}
::-webkit-scrollbar:horizontal {
  height: 4px;
}
.rtl {
  th {
    text-align: right;
  }
}