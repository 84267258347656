.mt-module {
  margin-top: $module !important;
}
.mr-module {
  margin-right: $module !important;
}
.mb-module {
  margin-bottom: $module !important;
}
.ml-module {
  margin-left: $module !important;
}
.bg-facebook {
  background: #3b5998;
}
.bg-twitter {
  background: #00ACED;
}
.bg-google {
  background: #DD4B39;
}
.info-card {
  position: relative;

  .title {
    line-height: 1;
    margin: 0;
  }
  .count {
    font-size: 32px;
  }
  .mat-icon {
    color: rgba(#fff,.2);
    font-size: 60px;
    height: auto;
    position: absolute;
    right: $module;
    top: $module;
    width: auto;

    ::deep .rtl & {
      left: $module;
      right: auto;
    }
  }
}
.chart-card {
  .card-wrap .card-content {
    align-items: flex-end;
    display: flex !important;
  }
  .chart {
    height: 80px;
    margin: 0;
    width: 80px;

    canvas {
      height: 100%;
    }
  }
  .info {
    padding-left: 1rem;

    ::deep .rtl & {
      padding-left: 0;
      padding-right: 1rem;
    }
    .title {
      font-size: 2rem;
      line-height: 1;
      margin: 0 0 .5rem;
    }
  }
}
.leaflet-top,
.leaflet-bottom {
  z-index: 996;
}
.elements-list {
  margin: -5px;

  > * {
    margin: 5px !important;
  }
}
.rtl {
  .text-right {
    text-align: left !important;
  }
  .text-left {
    text-align: right !important;
  }
}
