@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,300i,400,400i,700,700i|Open+Sans:300,300i,400,400i,700,700i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
html {
  height: 100%;
}

body {
  height: 100%;
  overflow-x: hidden;
}

app {
  display: block;
  height: 100%;
}

.site-container {
  height: 100%;
}
.site-container > * {
  display: block;
}
.site-container > router-outlet {
  display: none;
}
.site-container .main-content {
  height: calc(100% - 3.1428572rem);
  -webkit-overflow-scrolling: touch;
  background-color: white;
}
.site-container .main-content .main-content-wrap {
  display: table;
  height: 100%;
  padding: 22px;
  table-layout: fixed;
  width: 100%;
  background-color: white;
}

html {
  font-size: 14px;
  line-height: 22px;
}

body {
  background: #bbc2ce;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5714286;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  line-height: 1.5714286rem;
  letter-spacing: 0.01em;
  margin-top: 1.5714286rem;
  margin-bottom: 1.5714286rem;
}

h1, .h1 {
  font-size: 4.2142857rem;
  line-height: 1.13;
  margin-bottom: 3.1428572rem;
}
@media (max-width: 767px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

h2, .h2 {
  font-size: 3.1428571rem;
  line-height: 1;
}
@media (max-width: 767px) {
  h2, .h2 {
    font-size: 2.6rem;
  }
}

h3, .h3 {
  font-size: 2.3571429rem;
  line-height: 1.34;
}

h4, .h4 {
  font-size: 1.7857143rem;
  line-height: 1.32;
}

h5, .h5 {
  font-size: 1.3571429rem;
  line-height: 1.21;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.6;
}

p,
ul,
ol,
pre,
table,
blockquote,
.blockquote {
  margin-top: 0;
  margin-bottom: 1.5714286rem;
}
p:last-child,
ul:last-child,
ol:last-child,
pre:last-child,
table:last-child,
blockquote:last-child,
.blockquote:last-child {
  margin-bottom: 0;
}

ul ul, ol ol, ul ol, ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

hr, .hr {
  border: 1px solid;
  margin: -1px 0;
}

sub, sup {
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

a {
  color: #5dade0;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
a:hover, a:focus {
  outline: none;
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
}

button:focus {
  outline: none;
}

.form-group {
  align-items: center;
}

.form-message {
  left: 0;
  margin-top: 0.1rem;
  position: absolute;
  top: 100%;
}
::deep .rtl .form-message {
  left: auto;
  right: 0;
}

hr {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
  margin: 1.5714286rem 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  width: 12px;
}

::-webkit-scrollbar:hover {
  display: block;
}

::-webkit-scrollbar-thumb {
  background-color: #2e3d50;
}

::-webkit-scrollbar:horizontal {
  height: 4px;
}

.rtl th {
  text-align: right;
}

.mt-module {
  margin-top: 1.5714286rem !important;
}

.mr-module {
  margin-right: 1.5714286rem !important;
}

.mb-module {
  margin-bottom: 1.5714286rem !important;
}

.ml-module {
  margin-left: 1.5714286rem !important;
}

.bg-facebook {
  background: #3b5998;
}

.bg-twitter {
  background: #00ACED;
}

.bg-google {
  background: #DD4B39;
}

.info-card {
  position: relative;
}
.info-card .title {
  line-height: 1;
  margin: 0;
}
.info-card .count {
  font-size: 32px;
}
.info-card .mat-icon {
  color: rgba(255, 255, 255, 0.2);
  font-size: 60px;
  height: auto;
  position: absolute;
  right: 1.5714286rem;
  top: 1.5714286rem;
  width: auto;
}
::deep .rtl .info-card .mat-icon {
  left: 1.5714286rem;
  right: auto;
}

.chart-card .card-wrap .card-content {
  align-items: flex-end;
  display: flex !important;
}
.chart-card .chart {
  height: 80px;
  margin: 0;
  width: 80px;
}
.chart-card .chart canvas {
  height: 100%;
}
.chart-card .info {
  padding-left: 1rem;
}
::deep .rtl .chart-card .info {
  padding-left: 0;
  padding-right: 1rem;
}
.chart-card .info .title {
  font-size: 2rem;
  line-height: 1;
  margin: 0 0 0.5rem;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 996;
}

.elements-list {
  margin: -5px;
}
.elements-list > * {
  margin: 5px !important;
}

.rtl .text-right {
  text-align: left !important;
}
.rtl .text-left {
  text-align: right !important;
}

body .mat-button-toggle,
body .mat-button-base,
body .mat-button,
body .mat-raised-button,
body .mat-fab,
body .mat-icon-button,
body .mat-mini-fab,
body .mat-card,
body .mat-checkbox,
body .mat-input-container,
body .mat-list,
body .mat-menu-item,
body .mat-radio-button,
body .mat-select,
body .mat-list .mat-list-item .mat-list-item-content,
body .mat-nav-list .mat-list-item .mat-list-item-content,
body .mat-simple-snackbar,
body .mat-tab-label,
body .mat-slide-toggle-content,
body .mat-toolbar,
body .mat-tooltip,
body .mat-option,
body .mat-list .mat-subheader,
body .mat-nav-list .mat-subheader,
body .mat-paginator,
body .mat-paginator-page-size .mat-select-trigger {
  font-family: "Open Sans", sans-serif !important;
}
body [md-button],
body [md-fab],
body [md-icon-button],
body [md-mini-fab],
body [md-raised-button] {
  font-weight: inherit;
}
body .md-button-toggle-label {
  margin-bottom: 0;
}
body .mat-card .mat-card-header {
  margin-bottom: 1.5714286rem;
}
body .mat-card-avatar {
  overflow: hidden;
}
body .mat-card-image {
  max-width: none;
}
body .mat-card-title {
  margin-bottom: 0.5rem;
}
body .mat-card-subtitle:last-child {
  margin-bottom: 0;
}
body .mat-form-field {
  display: block;
  line-height: inherit;
}
body .mat-form-field .mat-input-prefix {
  padding-right: 0.7857143rem;
}
body .mat-form-field .mat-input-suffix {
  padding-left: 0.7857143rem;
}
body .rtl .mat-form-field .mat-input-prefix {
  padding-left: 0.7857143rem;
  padding-right: 0;
}
body .rtl .mat-form-field .mat-input-suffix {
  padding-left: 0;
  padding-right: 0.7857143rem;
}
body .mat-input-container {
  width: 100%;
}
body .mat-form-field-prefix > .mat-icon,
body .mat-form-field-suffix > .mat-icon {
  vertical-align: bottom;
}
body .mat-list .mat-list-item {
  color: inherit;
}
body .mat-list .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}
body .mat-list .mat-list-item .mat-list-item-content .mat-icon.mat-list-avatar {
  font-size: 40px;
}
body .mat-list .mat-list-item .mat-list-item-content h1, body .mat-list .mat-list-item .mat-list-item-content h2, body .mat-list .mat-list-item .mat-list-item-content h3, body .mat-list .mat-list-item .mat-list-item-content h4, body .mat-list .mat-list-item .mat-list-item-content h5, body .mat-list .mat-list-item .mat-list-item-content h6 {
  font-weight: 700;
  line-height: 1.3;
  overflow: hidden;
}
body .mat-progress-bar,
body .mat-progress-circle,
body .mat-spinner {
  margin-bottom: 1.5714286rem;
}
body .mat-progress-bar:last-child,
body .mat-progress-circle:last-child,
body .mat-spinner:last-child {
  margin-bottom: 0;
}
body .mat-radio-group {
  display: block;
  margin: 0 -11px 1.5714286rem;
}
body .mat-radio-group:last-child {
  margin-bottom: 0;
}
body .mat-radio-group .mat-radio-button {
  margin: 0 11px;
}
body .mat-radio-group .mat-radio-button .mat-radio-label {
  margin: 0;
}
body .column-layout .mat-radio-button {
  display: block;
  margin-bottom: 1.5714286rem;
}
body .column-layout .mat-radio-button:last-child {
  margin-bottom: 0;
}
body .mat-checkbox {
  display: inline-block;
  margin-bottom: 1.5714286rem;
  vertical-align: top;
}
body .mat-checkbox:last-child {
  margin-bottom: 0;
}
body .mat-checkbox-layout {
  margin-bottom: 0;
}
body .mat-option {
  font-size: inherit !important;
}
body .mat-tab-label .mat-icon {
  vertical-align: middle;
}
body .mat-tab-body-content {
  overflow: auto;
}
body .mat-icon {
  position: relative;
}
body .sb-item-head {
  font-weight: 700;
}
body .mat-paginator .mat-paginator-page-size-select {
  margin: 0 4px;
  width: 56px;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}